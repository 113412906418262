<template>
<div>
    <v-card>
        <v-card-title>
            Physician Informed Consent Form
        </v-card-title>

        <v-card-text>
            <div id="pdf_html">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>Sponsor/Program Title:</td>
                            <td>PXP Psoriasis</td>
                        </tr>
                        <tr>
                            <td>Protocol Number:</td>
                            <td>152-552</td>
                        </tr>
                        <tr>
                            <td>Principal Investigator:</td>
                            <td>Izzy</td>
                        </tr>
                        <tr>
                            <td>Telephone:</td>
                            <td>555-DOGGO</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>123 Skritches</td>
                        </tr>
                    </tbody>
                </table>
                
                <div style="padding: 25px; margin: 25px auto;">
                    <table class="consent-signature-container">
                        <tr>
                            <td>
                                Brennan K. Smith
                                <hr>
                                Name
                            </td>

                            <td>
                                <img :src="images.Signature" style="width: 80px;"/>
                                <hr>
                                Signature
                            </td>

                            <td>
                                {{today}}
                                <hr>
                                Date
                            </td>
                        </tr>
                    </table>
                </div>                

                <span v-html="css"/>
            </div>
        </v-card-text>
        
        <v-card-actions>
            <v-spacer></v-spacer>

                <template v-if="state=='done'">
                    <template v-if="submissions.length==0">
                        <v-checkbox v-model="form.agree" :value="true" :label="'I agree'"/>
                        <v-btn style="width: 150px;margin: 15px;" color="success" :disabled="form.agree!=true"  @click="submit">Submit</v-btn>
                    </template>

                    <template v-else>
                        <v-btn v-if="!pdfLink || !pdfLink.url" :disabled="states.pdf!='idle'" @click="getPDF">
                            <span v-if="states.pdf=='idle'">get PDF</span>
                            <v-icon v-else>mdi-spin mdi-loading</v-icon>
                        </v-btn>
                        <v-btn v-if="pdfLink && pdfLink.url" color="#261062" dark :link="true" :target="'_blank'" :href="pdfLink.url">
                            Download
                            <v-icon right style="color:white;">mdi-cloud-download</v-icon>
                        </v-btn>

                    </template>
                </template>

                <v-icon v-else>mdi-spin mdi-loading</v-icon>


            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import cache from '@/plugins/cache.js'
import Signature from './assets/signature.png'
export default {
    props: {
        consent_id: {
            type: Number,
            required: true
        },
        submissions: {
            type: Array,
            required: true
        },
        state: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            api: "https://api.vivomap.ca",
            states: {
                pdf: 'idle'
            },
            form: {
                agree: null
            },
            pdfLink: null
        }
    },
    methods: {
        getHTML: function(){
            function localizeImages(target){
                let imageElems = target.querySelectorAll("img")
                for(let i=0; i<imageElems.length; i++){
                    let elem = imageElems[i]
                    let target = elem.getAttribute('src')
                    // let src = self.images[target]

                    let relink = target.search("data:image/png;base64")<0 && target.search("http")<0

                    if(relink){
                        elem.src = window.location.origin+target
                    }
                }            
            }

            let target = document.getElementById('pdf_html')
            localizeImages(target)

            let html = target.innerHTML
            return html
        },
        getPDF: function(){
            let self = this
            self.states.pdf = 'generating'

            setTimeout(function(){
                let html = self.getHTML()
                self.sendRequest('post',self.api+'/pdf/make/cua',{
                    html
                }).then(function(response){
                    self.states.pdf = 'idle'
                    self.pdfLink = response.data
                    window.open(self.pdfLink.url,'Consent')
                })
            },3000)
        },
        submit: async function(){
            let date = new Date()
            this.$emit('submit',{
                consent_id: this.consent_id,
                agree: this.form.agree,
                user_id: this.$store.getters.userID,
                timestamp: date.getTime(),
                html: this.getHTML()
            })
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        images: function(){
            return {
                Signature
            }
        },
        today: function(){
            let today = new Date()
            return today.toDateString()
        },
        css: function(){
            return `
            <style type="text/css">
                .consent-signature-container{
                    width: 100%;
                }

                .consent-signature-container td{
                    width: 30%;
                    vertical-align: bottom;
                    padding: 15px;
                }
            </style>
            `
        }
    },
    watch: {
        submissions: function(){
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped lang="scss">
// .consent-signature-container{
//     margin: 25px;
//     display: flex; 
//     justify-content: space-evenly;
// }

// .consent-signature-container div{
//     width: 30%;
//     align-self: end;
// }
</style>